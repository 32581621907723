.card::after {
  content: "";
  background: #ed80fd;
  bottom: -5px;
  right: -5px;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.heading::after {
  height: 2px;
  width: 110%;
  position: absolute;
  bottom: 0;
  background: #ed80fd;
  -webkit-box-shadow: 0px 0px 15px 5px #000000;
  box-shadow: 0px 0px 15px 5px #ed80fd;
  left: 50%;
  transform: translateX(-50%);
}

.button::after {
  content: "";
  @apply bg-pinky;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  transform: skew(-15deg);
  transition: all 150ms;
}

.button:not(:disabled):hover::after {
  -webkit-box-shadow: 0px 0px 10px 1px #5345FB;
  box-shadow: 0px 0px 10px 1px #5345FB;
}