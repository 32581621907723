.navLink {
  @apply transition-all duration-150;
}

.navLink::after {
  content: "";
  @apply transition-all duration-150;
}

.active {
  opacity: 100;
}

.active {
  content: "";
  @apply bg-pinky;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0px 0px 15px 1px #5345FB;
  box-shadow: 0px 0px 8px 0.1px #5345FB;
  @apply rounded-lg;
}


.neon {
  border: 1px solid #5345FB;
}

.neon:hover {
  border: 2px solid #5345FB;
  -webkit-box-shadow: 0px 0px 8px 0.1px #5345FB;
  box-shadow: 0px 0px 8px 0.1px #5345FB;
}